<template>
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      relative
      flex flex-wrap
      justify-center
    "
  >
    <div
      class="container flex flex-1 justify-center flex-wrap"
    >
      <div class="w-full my-5 text-2xl text-center text-primary uppercase">
        Avisos de Privacidad
      </div>
    </div>

    <div

        ref="scroll"
        style="text-align: justify; padding: 18%; padding-top: 0%; height: 100%; top:0;"
      >



      <h1>El presente Aviso de Privacidad se entrega en cumplimiento a las disposiciones aplicables de la Ley Federal de Protección de Datos Personales en Posesión de Particulares ("Ley de Datos Personales''), su Reglamento y lineamientos aplicables.</h1>
      <br>

      <h3 class="text-primary">1. ANTECEDENTE</h3>
      <br>


      <h3 class="text-primary">2.- IDENTIDAD DEL “RESPONSABLE”</h3>
      <h1> THB RETAIL BROKERS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V. (THB RB) sociedad Mercantil constituida de conformidad con las leyes de los Estados Unidos Mexicanos con domicilio en Av. Coyoacán 1622, del Valle sur, Alcaldía Benito Juárez, Ciudad de México, México, C.P. 03104. </h1>
      <br>

    
      <h3 class="text-primary">3.- DATOS PERSONALES</h3>
      <h1>THB RB manifiesta que parte de la información que recabará de sus clientes y/o futuros asegurados como personas físicas, en y para la prestación de los servicios que ofrece, puede recibir sus Datos Personales y/o Datos Personales Sensibles, según dichos términos se definen a continuación, acorde a la Finalidad precisa que más adelante se define, y serán tratados bajo los principios de información, licitud, consentimiento, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, establecidos en la 'Ley de Datos Personales'.</h1>
      <br>
      <h3 class="text-primary">Datos Personales: Cualquier información concerniente a una persona física identificada o identificable, incluyendo de manera enunciativa más no limitativa:</h3>
      <h1>I. Datos de identificación: nombre completo, sexo o género, estado civil, firma, Registro Federal de Contribuyentes (R.F.C.), Clave Única de Registro de Población (C.U.R.P.), lugar y fecha de nacimiento, país de residencia, nacionalidad, edad, así como cualquier dato de identificación contenido en documentos oficiales, tales como, credencial para votar, pasaporte, cédula profesional, cartilla militar, entre otros medios de identificación emitidos por autoridades federales, estatales o municipales.</h1>
      <h1>II. Datos del contacto: domicilio, teléfono de casa, celular y/o de trabajo, correo electrónico, y números telefónicos de referencias personales.</h1>
      <h1>III. Datos laborales y profesionales: ocupación, profesión, actividad o giro al que se dedique el cliente, puesto de trabajo, área o departamento donde se labora, denominación o razón social, actividad económica preponderante o giro de la empresa donde labora, domicilio laboral, teléfono y correo de trabajo, referencias laborales, personales y comerciales.</h1>
      <h1>IV. Datos financieros o patrimoniales: números de cuenta, número de cliente, clabe interbancaria y datos bancarios.</h1>
      <h1>Datos Personales Sensibles: Aquellos que revelen o se relacionen con el estado y/o condiciones de salud.</h1>
      <br>




      <h3 class="text-primary">3.- FINALIDAD DEL TRATAMIENTO DE DATOS</h3>
      <h1>La finalidad del tratamiento de los Datos Personales y/o Datos Personales Sensibles será para:</h1>
      <h1>i) Que THB RB pueda colocar la(s) propuesta(s) del riesgo que reciba del cliente y futuro asegurado, para ser objeto de aseguramiento en el mercado asegurador nacional, esto es, para que se motive así la toma del riesgo por la entidad aseguradora que venga a asumir y tomar el riesgo ofertado o propuesto, la que dará en su oportunidad a conocer al asegurado y/o contratante su propio aviso de privacidad.</h1>
      <h1>ii) Cumplir con el marco normativo de la Ley de Instituciones de Seguros y Fianzas, la Ley del Contrato de Seguro, y el Reglamento de Agentes de Seguros y de Fianzas y/o las Circulares aplicables dictadas por la Comisión Nacional de Seguros y Fianzas, así como con la “Ley de Datos Personales”</h1>
      <h1>iii) Que THB RB pueda llevar a cabo la actividad de intermediación, esto es, el intercambio de propuestas y aceptación de las propuestas mismas, comercialización y asesoramiento para la celebración de los contratos de seguros, su conservación, modificación, renovación o cancelación</h1>
      <h1>iv) La Gestión y otorgamiento de pólizas de seguros; administración de las relaciones contractuales con aseguradoras, con empresas contratantes de seguros colectivos o grupales y los asegurados o clientes; así como para prestar a estos últimos servicios en el marco de la actividad de intermediación ejecutada por THB RB; para ofrecer informar y ofrecer nuevos servicios y productos, así como para el cumplimiento de las obligaciones que las leyes y reglamentos vigentes establezcan; y para hacer llegar al Titular de los Datos, formatos solicitudes y cuestionarios sobre los servicios brindados o que se pueden llegar a brindar a los clientes como asegurados y para hacer llegar a los mismos formatos para evaluar o medir la calidad en el servicio prestado por THB RB .</h1>
      <br>



      <h3 class="text-primary">4.- DERECHOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES</h3>
      <h1>Y/O DATOS PERSONALES SENSIBLES.</h1>
      <h1>THB RB manifiesta que cuenta con las medidas de seguridad suficientes para la protección, confidencialidad y aseguramiento del resguardo y protección de los Datos Personales y/o Datos Personales Sensibles del Titular, que reciba a efectos de restringir el acceso a los mismos a personas no autorizadas, así como para dar el tratamiento de los Datos Personales y/o Datos Personales Sensibles sin contravención lo estipulado en el presente Aviso de Privacidad. THB RB conviene en informar a sus empleados, representantes, subcontratistas, consultores o terceros que puedan tener acceso o recibir los Datos Personales y/o Datos Personales Sensibles del Titular, así como en informar a encargados si los hubiera, respecto de su tratamiento, y de todas las obligaciones que se tienen respecto de la protección de los Datos Personales y/o Datos Personales Sensibles recibidos del Titular, de conformidad con lo previsto en este Aviso de Privacidad y la legislación aplicable vigente.</h1>
      <h1>THB RB, sus empleados, representantes, subcontratistas, consultores y/o los terceros que intervengan en cualquier fase del tratamiento de los Datos Personales y/o Datos Personales Sensibles del Titular deberán guardar confidencialidad respecto de éstos, obligación que subsistirá aún después de finalizar la relación entre THB RB con el Titular, y misma obligación que aplicará en y para el supuesto en que un encargado tratará los datos personales por cuenta de THB RB.</h1>
      <br>





      <h3 class="text-primary">5.- ALMACENAMIENTO DE DATOS PERSONALES.</h3>
      <h1>Los Datos Personales y/o Datos Personales Sensibles se almacenan en una o más bases de datos temporales o definitivas por el tiempo necesario para el cumplimiento de los contratos que se tengan celebrados y de las responsabilidades nacidas de los mismos en tanto no exista disposición legal o mandato judicial en otro sentido, o el Titular no solicite expresamente la cancelación de los Datos Personales y/o Datos Personales Sensibles, siempre que ésta proceda en términos de la "Ley de Datos Personales'.</h1>
      <br>





      <h3 class="text-primary">6.- MEDIOS PARA EJERCER LOS DERECHOS ARCO.</h3>
      <h1>De conformidad con la "Ley de Datos Personales' en cualquier momento el Titular tendrá derecho a ejercer sus derechos de acceso, rectificación, cancelación y oposición (Derechos ARCO) respecto de sus Datos Personales y/o Datos Personales Sensibles directamente ante THB RB, mediante la descarga de la solicitud a través del vínculo web www.thbretailbrokers.com.mx y descargando el archivo desde el link denominado "DERECHOS ARCO" y presentándolo en el domicilio indicado en este aviso de privacidad o enviándolo al correo electrónico info@thbmexico.com, indicando lo siguiente:</h1>
      <h1>i) Nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;</h1>
      <h1>ii) Documentos que acrediten la identidad del titular o en su caso la representación legal respecto del Titular;</h1>
      <h1>iii) Descripción clara y precisa de los Datos Personales y/o Datos Personales Sensibles respecto de los que se busca ejercer cualquiera de los derechos ARCO; y</h1>
      <h1>iv) Cualquier otro elemento o documento que facilite la localización de los Datos Personales y/o Datos Personales Sensibles del Titular.</h1>
      <h1>En caso de que alguno de los Datos Personales y/o Datos Personales Sensibles del Titular sea inexacto o incompleto, el Titular además de indicar lo señalado anteriormente, deberá señalar las modificaciones a realizarse y aportar la documentación necesaria que sustente su petición.</h1>
      <h1>El Titular puede solicitar que se cancelen o supriman los Datos Personales y/o Datos Personales Sensibles que THB RB conserve en su expediente, al concluirse con los servicios prestados por THB RB al Titular, mediante el acceso al vínculo web y link previamente señalados. Una vez cancelado el o los datos, THB RB notificará al titular.</h1>
      <h1>De ejercer el Titular el derecho a la oposición en el tratamiento de los Datos Personales y/o Datos Personales Sensibles por parte de THB RB , a través del procedimiento en este apartado previsto y de resultar procedente la misma se le informa la titular de los datos. Por su parte THB RB no podrá continuar usando dichos datos, hasta en tanto el Titular no autorice de nueva cuenta su tratamiento en forma expresa.</h1>
      <br>


      <h3 class="text-primary">7.-TRANSFERENCIA DE DATOS</h3>
      <h1>THB RB podrá transmitir los Datos Personales para el cumplimiento mismo de la finalidad aludida en este aviso, esto es para, los fines mismos del tratamiento de datos objeto de este aviso dentro e incluso fuera de la República Mexicana y los podrá transferir a las siguientes entidades:</h1>
      <h1>A las aseguradoras que conforman el mercado asegurador y afianzador nacional, así como a:</h1>
      <h1>a) Compañías Afiliadas</h1>
      <h1>THB RB podrá transmitir sus Datos Personales a aquellas sociedades que forman parte de su grupo corporativo (incluyendo en forma enunciativa, más no limitativa, empresas subsidiarias y filiales que presten servicios en nombre de THB RB).</h1>
      <h1>b) Terceros, no Afiliadas</h1>
      <h1>THB RB podrá transmitir sus Datos Personales a aquellos terceros no afiliados, prestadores de servicios con el propósito de que éstos asistan en la prestación de servicios establecidos en el contrato que celebren y donde, THB RB y usted sean parte.
      Asi mismo, podrá transmitirlos a aquellas personas morales titulares/contratantes de seguros grupales o colectivos, al amparo de los cuales se emitan pólizas solicitadas donde el titular de los datos venga a quedar ubicado como inciso/asegurado.
      </h1>
      <h1>c) Socios Comerciales
THB RB podrá transmitir sus Datos Personales a sus Socios Comerciales, con el propósito de que dichos Socios lo contacten para ofrecerle productos y servicios.
</h1>
      <h1>THB RB manifiesta que ha tomado medidas razonables, necesarias y a su alcance para proteger los datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. El acceso a esta información se limita a las personas que deben de conocerla para cumplir con el objeto de su trabajo, y en el marco de asistencia asesoria y servicios que se brinden, en beneficio del titular de los datos.</h1>
      <br>


      <h3 class="text-primary">8.- CONSENTIMIENTO</h3>
      <h1>El Titular consiente, al momento de la firma del presente Aviso de Privacidad, en proporcionar sus Datos Personales y/o Datos Personales Sensibles en la forma y para los fines señalados en este aviso y que los mismos sean transmitidos a aseguradoras y/o cualquier otro proveedor o entidad referida en el numeral anterior, única y exclusivamente para los fines de emisión de pólizas de seguros, y la prestación de los servicios que ofrece THB RB.</h1>
      <br>


      <h3 class="text-primary">9.- CAMBIOS AL AVISO DE PRIVACIDAD</h3>
      <h1>Cualquier cambio o modificación que se realice al Aviso de Privacidad se hará del conocimiento del Titular a través de la página web www.thbretailbrokers.com.mx o notificada al mismo mediante correo electrónico a la dirección que para tales efectos proporcione y mantenga registrado éste con THB RB.</h1>
      <br>


      <h3 class="text-primary">CONTÁCTENOS</h3>
      <h1>Si tiene alguna pregunta relacionada con esta Política de Privacidad, por favor póngase en contacto con nosotros en Av. Coyoacán 1622, del Valle sur, Alcaldía Benito Juárez, Ciudad de México, México, C.P. 03104 ó en el correo electrónico info@thbretailbrokers.com.mx</h1>
      <br>


      </div>


  </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>